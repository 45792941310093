export default function ({ $axios, store, redirect }) {
  $axios.onResponse(function (response) {
    switch (response.status) {
      case 205:
        store.$auth.logout();
        redirect("/");
        break;
    }
  });
  $axios.onError(function (error) {
    const auth0RequestURLRegex = /https:\/\/.*\.auth0\.com\/userinfo/;
    if (!auth0RequestURLRegex.test(error.config.url) && error.response) {
      switch (error.response.status) {
        case 401:
          store.commit("setMsg", "");
          store.$auth.logout();
          redirect("/");
          break;
        case 403:
          store.commit("setMsg", "unauthorized");
          break;
      }
    }
  });
}
